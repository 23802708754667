<template>
	<div>
		<v-card
			:tile="tile"
			:outlined="outlined"
			:flat="flat"
			class="v-card--material px-5 py-3">
			<template v-if="!loading">
				<slot
					name="heading"
					:item="item">
					<card-heading
						v-if="title"
						:color="color"
						:title="title"></card-heading>
				</slot>
				<span
					style="max-height: 80vh; overflow-y: auto"
					:class="stretch"
					v-if="item !== null && item !== undefined">
					<template v-for="(row, index) in rows">
						<v-row :key="`row${index}`">
							<template v-for="col in row">
								<v-col :key="`column-${col.key}`">
									<template v-if="col.hide != true">
										<v-list-item>
											<v-list-item-content
												class="text-body-1"
												v-if="col.label !== null && !col.fullsize">
												<span>
													<slot
														:name="`item.${col.key}.label`"
														:item="item">
														{{ col.label }}{{ col.label ? ":" : "" }}
													</slot>
												</span>
											</v-list-item-content>
											<v-list-item-content
												class="text-body-2"
												:class="`align-${col.align ?? 'end'} text-${
													col.align ?? 'end'
												}`">
												<div class="align-center">
													<span
														class="text-body-1 mr-5"
														v-if="col.fullsize && col.label !== null">
														{{ col.label }}:
													</span>
													<span :class="`${valuesColor}--text`">
														<slot
															:name="`item.${col.key}.value`"
															:item="item">
															{{
																col.key
																	.split(".")
																	.reduce(
																		(prev, next) =>
																			prev[next] !== null ||
																			prev[next] !== undefined
																				? prev[next]
																				: undefined,
																		item
																	)
															}}
														</slot>
													</span>
												</div>
											</v-list-item-content>
										</v-list-item>
									</template>
								</v-col>
							</template>
						</v-row>
						<v-divider
							:key="`divider${index}`"
							v-if="index < rows.length - 1"></v-divider>
					</template>
				</span>
				<v-card-text v-else>
					<slot name="nodData">No data</slot>
				</v-card-text>
			</template>
			<template v-else>
				<v-skeleton-loader type="card" />
			</template>
			<v-card-actions
				v-if="
					Object.keys($slots)
						.join(Object.keys($scopedSlots))
						.includes('actions')
				">
				<slot name="actions"></slot>
			</v-card-actions>
		</v-card>
	</div>
</template>
<script>
import CardHeading from "./CardHeading.vue";

export default {
	components: { CardHeading },
	props: {
		item: { type: Object },
		cols: { ype: Array },
		loading: { type: Boolean, default: true },
		tile: { type: Boolean, default: false },
		outlined: { type: Boolean, default: false },
		flat: { type: Boolean, default: false },
		color: { type: String, default: "secondary" },
		stretch: { type: String, default: "" },
		title: { type: String, default: undefined },
		valuesColor: { type: String, default: "kajot-text" },
	},
	computed: {
		rows() {
			return this.cols
				.map((el) => (Array.isArray(el) ? el : [el]))
				.filter((el) => el.length > 0);
		},
	},
};
</script>
