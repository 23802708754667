<template>
	<v-card
		@keyup.enter="emitFilter"
		class="pa-0 ma-0"
		elevation="0">
		<!-- <v-row
			><v-col cols="12" lg="12" class="pb-0">
				<v-date-picker
					class="mt-4"
					color="primary"
					header-color="defaultbg"
					@input="(e) => logInput(e)"
					full-width
					range
					flat
					:title-date-format="dateFormatter"
					:type="pickerType"></v-date-picker> </v-col
		></v-row> -->

		<v-row>
			<!-- TODO: Clear button -->
			<v-col
				cols="12"
				class="mt-3 mb-0 pb-0">
				<v-icon
					v-if="_date_from || _date_to"
					@click="clearCreated"
					class="primary--text">
					mdi-close
				</v-icon>
				Created
			</v-col>
			<v-col
				cols="12"
				lg="6"
				class="pb-0">
				<DatetimePicker
					:createdNow="false"
					:min="timeEpoch"
					title="Date From"
					ref="dateFrom"
					:key="_date_from"
					v-model="_date_from" />
			</v-col>
			<v-col
				cols="12"
				lg="6"
				class="pb-0">
				<DatetimePicker
					:createdNow="false"
					:min="timeEpoch"
					title="Date To"
					ref="dateTo"
					:key="_date_to"
					v-model="_date_to" />
			</v-col>

			<v-col
				cols="12"
				lg="6"
				class="pb-0">
				<v-text-field
					label="Min Bet"
					@change="(e) => onChange({ min_bet: parseFloat(e) })"
					:value="value.min_bet"
					type="number"></v-text-field>
			</v-col>
			<v-col
				cols="12"
				lg="6"
				class="pb-0">
				<v-text-field
					label="Max Bet"
					@change="(e) => onChange({ max_bet: parseFloat(e) })"
					:value="value.max_bet"
					type="number"></v-text-field>
			</v-col>
			<!-- <v-col cols="12" lg="6" class="pb-0">
				<v-text-field
					label="Min Round Bet"
					@change="(e) => onChange({ min_round_bet: parseFloat(e) })"
					:value="value.min_round_bet"
					type="number"></v-text-field>
			</v-col>
			<v-col cols="12" lg="6" class="pb-0">
				<v-text-field
					label="Max Round Bet"
					@change="(e) => onChange({ max_round_bet: parseFloat(e) })"
					:value="value.max_round_bet"
					type="number"></v-text-field>
			</v-col> -->
			<v-col
				cols="12"
				lg="6"
				class="pb-0">
				<v-text-field
					label="Min Win"
					@change="(e) => onChange({ min_win: parseFloat(e) })"
					:value="value.min_win"
					type="number"></v-text-field>
			</v-col>
			<v-col
				cols="12"
				lg="6"
				class="pb-0">
				<v-text-field
					label="Max Win"
					@change="(e) => onChange({ max_win: parseFloat(e) })"
					:value="value.max_win"
					type="number"></v-text-field>
			</v-col>
			<v-col
				cols="12"
				lg="6"
				class="">
				<v-text-field
					label="Min Rounds"
					@change="(e) => onChange({ min_games: parseFloat(e) })"
					:value="value.min_games"
					type="number"></v-text-field>
			</v-col>
			<v-col
				cols="12"
				lg="6"
				class="pb-0">
				<v-text-field
					label="Max Rounds"
					@change="(e) => onChange({ max_games: parseFloat(e) })"
					:value="value.max_games"
					type="number"></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-autocomplete
					v-model="selectedGames"
					:items="stateGamesList.data"
					:loading="stateGamesList.isLoading"
					label="Games"
					item-text="game_name"
					item-value="technical"
					multiple
					chips
					persistent-hint
					hide-no-data
					small-chips
					clearable
					clear-icon="mdi-close primary--text"
					deletable-chips
					:menu-props="{ offsetY: true }"
					@change="(e) => onChange({ games: e })">
					<template v-slot:item="{ item, index }">
						<v-row
							dense
							class="text-body-2 d-flex justify-space-between">
							<span>{{ item.game_name }}</span>
							<v-chip
								v-if="item.removed"
								small>
								Removed
							</v-chip>
						</v-row>
						<span
							v-if="index === 5"
							class="grey--text text-caption">
							(+{{ value.games?.length - 5 }} others)
						</span>
					</template>
					<template v-slot:selection="{ item, index, select, selected }">
						<v-chip
							small
							close
							:key="`gameKey-${item.technical}`"
							@click:close="removeElement(index, value.games, select, selected)"
							v-if="index < 5">
							<span>{{ item.game_name }}</span>
						</v-chip>
						<span
							v-if="index === 5"
							class="grey--text text-caption">
							(+{{ selectedGames?.length - 5 }} others)
						</span>
					</template>
					<template v-slot:prepend-item>
						<div
							v-ripple
							:class="
								selectedGames?.length === stateGamesList.data?.length
									? ''
									: 'primary--text'
							"
							class="selectall px-3 text-body-2 d-flex align-center justify-space-between"
							@mousedown.prevent
							@click="toggleAllGames">
							{{
								selectedGames?.length === stateGamesList.data?.length
									? "Deselect All"
									: "Select All"
							}}
						</div>
					</template>
				</v-autocomplete>
				<EditComponent
					noDialog
					:fullArray="currencyCodes"
					v-model="selectedCurrencies"
					multiple
					placeholderText="Currency"></EditComponent>
			</v-col>
		</v-row>
		<div class="d-flex justify-center">
			<v-btn
				:disabled="filterIsEmpty"
				@click="emitFilter"
				color="primary">
				Apply
			</v-btn>
		</div>
	</v-card>
</template>

<script>
import DatetimePicker from "../forms/DatetimePicker.vue";
// TODO : BE FILTER OBJECT
import EditComponent from "../jackpots/EditComponent.vue";
import { currencyCodes } from "../../constants/currencyCodes";

import { mapGetters, mapActions } from "vuex";

export default {
	model: {
		prop: "value",
		event: "change",
	},
	components: {
		DatetimePicker,
		EditComponent,
	},
	data() {
		return {
			currencyCodes,
			timeEpoch: new Date(0),
		};
	},
	props: {
		expanded: { type: Boolean, default: false },
		value: { type: Object, default: () => ({}) },
		autoFilter: {
			type: Boolean,
			default: false,
		},
		filterIsEmpty: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		_date_from: {
			get() {
				return this.value.date_from;
			},
			set(val) {
				this.$emit("change", {
					...this.value,
					date_from: val,
				});
			},
		},
		_date_to: {
			get() {
				return this.value.date_to;
			},
			set(val) {
				this.$emit("change", {
					...this.value,
					date_to: val,
				});
			},
		},
		selectedGames: {
			get() {
				return this.value.games;
			},
			set(val) {
				this.onChange({ games: val });
			},
		},
		selectedCurrencies: {
			get() {
				return this.value.currency;
			},
			set(val) {
				this.onChange({ currency: val });
			},
		},
		...mapGetters("meta2", {
			gamesList: "allowedGames",
		}),
		stateGamesList() {
			if (this.gamesList) {
				return {
					data: [...this.gamesList].sort((a, b) =>
						a.technical > b.technical ? 1 : -1
					),
					isLoading: this.gamesList.false,
				};
			}
			return { data: [], isLoading: true };
		},
	},
	methods: {
		async emitFilter() {
			if (this.filterIsEmpty) return;
			this.$emit("filter");
		},
		clearCreated() {
			this.$emit("change", {
				...this.value,
				date_from: undefined,
				date_to: undefined,
			});
		},
		removeElement(idx, array) {
			this.$nextTick(() => {
				array.splice(idx, 1);
				this.selectedGames = array;
			});
		},
		toggleAllGames() {
			this.$nextTick(() => {
				if (this.selectedGames?.length === this.stateGamesList.data?.length) {
					this.selectedGames = [];
				} else {
					this.selectedGames = [
						...this.stateGamesList.data.map((el) => el?.technical),
					];
				}
			});
		},
		onClear() {
			this.$emit("change", {
				min_bet: undefined,
				max_bet: undefined,
				min_win: undefined,
				max_win: undefined,
				min_games: undefined,
				max_games: undefined,
				// min_round_bet: undefined,
				// max_round_bet: undefined,
				date_from: undefined,
				date_to: undefined,
				games: [],
				currency: [],
			});
		},
		onChange(e) {
			this.$emit("change", {
				...this.value,
				...e,
			});
		},
	},
};
</script>

<style>
.selectall {
	min-height: 48px;
	cursor: pointer;
}

.selectall:hover::after {
	background-color: currentColor;
	bottom: 0px;
	content: "";
	left: 0;
	opacity: 0.1;
	pointer-events: none;
	position: absolute;
	right: 0;
	top: 0;
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.filterClear {
	position: fixed;
	bottom: 17vh;
	z-index: 2;
}
</style>
