var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "pa-0 ma-0",
      attrs: { elevation: "0" },
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          return _vm.emitFilter.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mt-3 mb-0 pb-0", attrs: { cols: "12" } },
            [
              _vm._date_from || _vm._date_to
                ? _c(
                    "v-icon",
                    {
                      staticClass: "primary--text",
                      on: { click: _vm.clearCreated },
                    },
                    [_vm._v(" mdi-close ")]
                  )
                : _vm._e(),
              _vm._v(" Created "),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { cols: "12", lg: "6" } },
            [
              _c("DatetimePicker", {
                key: _vm._date_from,
                ref: "dateFrom",
                attrs: {
                  createdNow: false,
                  min: _vm.timeEpoch,
                  title: "Date From",
                },
                model: {
                  value: _vm._date_from,
                  callback: function ($$v) {
                    _vm._date_from = $$v
                  },
                  expression: "_date_from",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { cols: "12", lg: "6" } },
            [
              _c("DatetimePicker", {
                key: _vm._date_to,
                ref: "dateTo",
                attrs: {
                  createdNow: false,
                  min: _vm.timeEpoch,
                  title: "Date To",
                },
                model: {
                  value: _vm._date_to,
                  callback: function ($$v) {
                    _vm._date_to = $$v
                  },
                  expression: "_date_to",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { cols: "12", lg: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Min Bet",
                  value: _vm.value.min_bet,
                  type: "number",
                },
                on: { change: (e) => _vm.onChange({ min_bet: parseFloat(e) }) },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { cols: "12", lg: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Max Bet",
                  value: _vm.value.max_bet,
                  type: "number",
                },
                on: { change: (e) => _vm.onChange({ max_bet: parseFloat(e) }) },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { cols: "12", lg: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Min Win",
                  value: _vm.value.min_win,
                  type: "number",
                },
                on: { change: (e) => _vm.onChange({ min_win: parseFloat(e) }) },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { cols: "12", lg: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Max Win",
                  value: _vm.value.max_win,
                  type: "number",
                },
                on: { change: (e) => _vm.onChange({ max_win: parseFloat(e) }) },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Min Rounds",
                  value: _vm.value.min_games,
                  type: "number",
                },
                on: {
                  change: (e) => _vm.onChange({ min_games: parseFloat(e) }),
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { cols: "12", lg: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Max Rounds",
                  value: _vm.value.max_games,
                  type: "number",
                },
                on: {
                  change: (e) => _vm.onChange({ max_games: parseFloat(e) }),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-autocomplete", {
                attrs: {
                  items: _vm.stateGamesList.data,
                  loading: _vm.stateGamesList.isLoading,
                  label: "Games",
                  "item-text": "game_name",
                  "item-value": "technical",
                  multiple: "",
                  chips: "",
                  "persistent-hint": "",
                  "hide-no-data": "",
                  "small-chips": "",
                  clearable: "",
                  "clear-icon": "mdi-close primary--text",
                  "deletable-chips": "",
                  "menu-props": { offsetY: true },
                },
                on: { change: (e) => _vm.onChange({ games: e }) },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function ({ item, index }) {
                      return [
                        _c(
                          "v-row",
                          {
                            staticClass:
                              "text-body-2 d-flex justify-space-between",
                            attrs: { dense: "" },
                          },
                          [
                            _c("span", [_vm._v(_vm._s(item.game_name))]),
                            item.removed
                              ? _c("v-chip", { attrs: { small: "" } }, [
                                  _vm._v(" Removed "),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        index === 5
                          ? _c(
                              "span",
                              { staticClass: "grey--text text-caption" },
                              [
                                _vm._v(
                                  " (+" +
                                    _vm._s(_vm.value.games?.length - 5) +
                                    " others) "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "selection",
                    fn: function ({ item, index, select, selected }) {
                      return [
                        index < 5
                          ? _c(
                              "v-chip",
                              {
                                key: `gameKey-${item.technical}`,
                                attrs: { small: "", close: "" },
                                on: {
                                  "click:close": function ($event) {
                                    return _vm.removeElement(
                                      index,
                                      _vm.value.games,
                                      select,
                                      selected
                                    )
                                  },
                                },
                              },
                              [_c("span", [_vm._v(_vm._s(item.game_name))])]
                            )
                          : _vm._e(),
                        index === 5
                          ? _c(
                              "span",
                              { staticClass: "grey--text text-caption" },
                              [
                                _vm._v(
                                  " (+" +
                                    _vm._s(_vm.selectedGames?.length - 5) +
                                    " others) "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "prepend-item",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            directives: [
                              { name: "ripple", rawName: "v-ripple" },
                            ],
                            staticClass:
                              "selectall px-3 text-body-2 d-flex align-center justify-space-between",
                            class:
                              _vm.selectedGames?.length ===
                              _vm.stateGamesList.data?.length
                                ? ""
                                : "primary--text",
                            on: {
                              mousedown: function ($event) {
                                $event.preventDefault()
                              },
                              click: _vm.toggleAllGames,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.selectedGames?.length ===
                                    _vm.stateGamesList.data?.length
                                    ? "Deselect All"
                                    : "Select All"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.selectedGames,
                  callback: function ($$v) {
                    _vm.selectedGames = $$v
                  },
                  expression: "selectedGames",
                },
              }),
              _c("EditComponent", {
                attrs: {
                  noDialog: "",
                  fullArray: _vm.currencyCodes,
                  multiple: "",
                  placeholderText: "Currency",
                },
                model: {
                  value: _vm.selectedCurrencies,
                  callback: function ($$v) {
                    _vm.selectedCurrencies = $$v
                  },
                  expression: "selectedCurrencies",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-center" },
        [
          _c(
            "v-btn",
            {
              attrs: { disabled: _vm.filterIsEmpty, color: "primary" },
              on: { click: _vm.emitFilter },
            },
            [_vm._v(" Apply ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }